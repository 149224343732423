/* istanbul ignore file */
// @ts-nocheck

// WARNING: DO NOT EDIT THIS FILE UNLESS YOU ARE SURE ABOUT WHAT YOU ARE DOING. MIGHT CAUSE UNEXPECTED LINK ISSUES

import { useRouter } from 'next/router';
import NextLink, { LinkProps } from 'next/link';

export const Link: React.FC<LinkProps> = ({ children, href, ...props }) => {
  const router = useRouter();

  let hrefObj = {
    pathname: href.pathname || '',
    query: {},
  };

  if (typeof href === 'string') {
    hrefObj.pathname = href;
  }

  if (href.query) {
    hrefObj.query = href.query;
  }

  const queries = {} as Record<string, string>;
  Object.entries(router.query).forEach((q: [string, string]) => {
    if (q[0].toLowerCase().includes('utm') || q[0].toLowerCase().includes('gclid')) {
      queries[q[0]] = q[1];
    }
  });

  return (
    <NextLink
      href={{
        pathname: hrefObj.pathname || (href as string),
        query: {
          ...queries,
          ...hrefObj.query,
        },
      }}
      {...props}
    >
      {children}
    </NextLink>
  );
};
